.topmostheader {
  height: 10px;
  background-color: #7621ec;
}

.navbar {
  box-shadow: 6px 6px 17px rgb(0 0 0 / 25%);
  background-color: #fff;
}

.navbar a {
  color: #7621ec;
  font-weight: 700;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 16px;
  font-family: "Roboto Slab", serif;
}

.bottomNavbar {
  height: 120px;
}

.closeIcon {
  position: absolute;
  right: 35px;
  top: 30px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.sidebar {
  margin-top: 100px;
  text-align: center;
}

.sidebar li {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.sidebar a:hover {
  color: #fff;
}

/* Notification */
.toastContainer {
  z-index: 9999999;
}

.notification {
  background: #7621ec;
  color: #fff;
  margin-bottom: 60%;
  margin-right: 35px;
  width: max-content;
  z-index: 9999999;
}

.notification div {
  background: #7621ec;
  color: #fff;
  border: none;
  box-shadow: none;
  font-size: 40px;
  font-weight: 600;
}

@media only screen and (min-width: 1400px) {
  .navbar a {
    font-size: 22px;
  }

  .navbar-brand img {
    width: 250px;
    height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .navbar a {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}