.links > .nav-item {
  color: black;
  padding: 0.75em;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 14px;
}

.links > .nav-item:hover {
  color: white;
}

.links > .line {
  position: absolute;
  height: 35px;
  top: -4px;
  z-index: 1;
}

/* The Magic */
.links {
  display: grid;
  grid-template-columns: repeat(var(--items), 1fr);
  position: relative;
  list-style: none;
  padding-left: 0;
}

.links > .line {
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  bottom: 0;
  left: var(--left, calc(100% / var(--items) * (var(--index) - 1)));
  width: var(--width, calc(100% / var(--items)));

  --index: 0;
}

.links > .nav-item {
  /* width: 250px; */
  position: relative;
  z-index: 2;
  padding: 0;
}

.links > .nav-item:hover a {
  color: white;
}

.links > .nav-item:hover ~ .line {
  opacity: 1;
  background: #7621ec;
  border-radius: 16px;

  /* width: 200px; */
}

.links > .nav-item:nth-of-type(1):hover ~ .line {
  --index: 1;
}

.links > .nav-item:nth-of-type(2):hover ~ .line {
  --index: 2;
}

.links > .nav-item:nth-of-type(3):hover ~ .line {
  --index: 3;
}

.links > .nav-item:nth-of-type(4):hover ~ .line {
  --index: 4;
}

.links > .nav-item:nth-of-type(5):hover ~ .line {
  --index: 5;
}

.links > .nav-item:nth-of-type(6):hover ~ .line {
  --index: 6;
}

.links > .nav-item:nth-of-type(7):hover ~ .line {
  --index: 7;
}

.links > .nav-item:nth-of-type(8):hover ~ .line {
  --index: 8;
}

.links > .nav-item:nth-of-type(9):hover ~ .line {
  --index: 9;
}

.links > .nav-item:nth-of-type(10):hover ~ .line {
  --index: 10;
}

.links > .nav-item:last-of-type:hover ~ .line {
  --index: var(--items);
}
